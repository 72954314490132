<template>
  <div class="py-6">
    <template v-if="userIsAdmin">
      <div class="px-5">
        <h2>Selecione uma CRC</h2>
        <p>
          Antes de iniciar uma nova destinação é preciso selecionar a CRC
          pertencente.
        </p>
      </div>
      <select-crc
        v-model="selectedCrc"
        :multiple="false"
        @selected="handleCrcSelected"
      />
    </template>
    <template v-else>
      <div class="px-5">
        <h2>CRC</h2>
        <p>{{ selectedCRCDescription }}</p>
      </div>
    </template>
  </div>
</template>

<script>
  import SelectCRC from "@/modules/crc/views/components/SelectCRC";

  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "DestinationSelectCRC",

    components: {
      SelectCrc: SelectCRC
    },

    data: () => ({
      selectedCrc: undefined
    }),

    mounted() {
      if (!this.userIsAdmin) {
        this.setSelectedCRC();
        this.$emit("invalid", false);
      }
    },

    computed: {
      ...mapGetters({
        userIsAdmin: "user/getUserIsAdmin"
      }),

      ...mapGetters("user", {
        selectedUserCRC: "getSelectedUserCRC"
      }),

      ...mapGetters("stock/destinationDemanufacturing", {
        selectedCRCStore: "getCreateDestinationSelectedCrc"
      }),

      selectedCRCDescription() {
        return this.selectedUserCRC.description;
      }
    },

    methods: {
      ...mapActions("stock/destinationDemanufacturing", [
        "setCreateDestinationSelectedCrc"
      ]),

      handleCrcSelected(crc) {
        if (crc) {
          this.setCreateDestinationSelectedCrc({
            id: crc.id,
            name: crc.description
          });
        } else {
          this.setCreateDestinationSelectedCrc({
            id: undefined,
            name: undefined
          });
        }
        // this.resetCreateDestinationSelectedEquipments();
        this.$emit("invalid", !crc?.id);
      },

      setSelectedCRC() {
        this.setCreateDestinationSelectedCrc({
          id: this.selectedUserCRC.id,
          name: this.selectedUserCRC.description
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
