<template>
  <div>
    <h4>{{ value.description }}</h4>

    <template v-for="(residueSubtype, index) in value.selectedResidueSubtypes">
      <residue-weight-field
        class="px-2 pt-2"
        :key="`weight-field-${index}`"
        :value="residueSubtype"
        @input="handleChangeResidueSubtype(index, $event)"
        suffix="Kg"
        :name="`weight-field-${residueSubtype.id}`"
        :isRequired="true"
      />
    </template>
  </div>
</template>

<script>
  import ResidueWeightField from "@/modules/stock/destination-demanufacturing/views/components/ResidueWeightField";

  export default {
    name: "ResidueWeightContainer",

    props: {
      value: {
        type: null,
        required: true
      }
    },

    components: {
      ResidueWeightField
    },

    methods: {
      handleChangeResidueSubtype(index, newValue) {
        const copy = {
          ...this.value,
          selectedResidueSubtypes: [...this.value.selectedResidueSubtypes]
        };
        copy.selectedResidueSubtypes[index] = newValue;
        this.$emit("input", copy);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
