var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('title-header',{attrs:{"title":_vm.$route.meta.title,"subtitle":_vm.subtitle}}),(_vm.stepper.currentStep === _vm.steps.LIST_RESIDUES_AND_SELECT_CRC)?_c('list-residue-stock',{scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"ma-0 mt-1 white--text next-step-button",attrs:{"type":"button","disabled":invalid,"color":"secondary-color-1","block":_vm.$isMobile},on:{"click":_vm.handleInitDestination}},[_vm._v("Nova Destinação")])],1)]}}],null,false,2583653002)}):_vm._e(),(_vm.stepper.currentStep === _vm.steps.SELECT_RESIDUES)?_c('select-residue',{scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex justify-end flex-wrap"},[_c('v-btn',{staticClass:"mt-1 black-3--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","color":"secondary-color-1","block":_vm.$isMobile,"outlined":""},on:{"click":_vm.handleBackActionOnFirstStep}},[_vm._v(" Voltar ")]),_c('v-btn',{staticClass:"mt-1 white--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","disabled":invalid,"color":"secondary-color-1","block":_vm.$isMobile},on:{"click":_vm.handleSelectedResidues}},[_vm._v("Avançar")])],1)]}}],null,false,400312702)}):_vm._e(),(_vm.stepper.currentStep === _vm.steps.SELECT_RECYCLER)?_c('select-recycler',{scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex justify-end flex-wrap"},[_c('v-btn',{staticClass:"mt-1 black-3--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","color":"secondary-color-1","block":_vm.$isMobile,"outlined":""},on:{"click":_vm.handleBackActionOnSecondStep}},[_vm._v(" Voltar ")]),_c('v-btn',{staticClass:"mt-1 white--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","disabled":invalid,"color":"secondary-color-1","block":_vm.$isMobile},on:{"click":_vm.handleSelectedRecycler}},[_vm._v("Avançar")])],1)]}}],null,false,2899670987)}):_vm._e(),(_vm.stepper.currentStep === _vm.steps.SUMMARY)?_c('summary-step',{scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex justify-end flex-wrap"},[_c('v-btn',{staticClass:"mt-1 black-3--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","color":"secondary-color-1","block":_vm.$isMobile,"outlined":""},on:{"click":_vm.handleBackActionOnThirdStep}},[_vm._v(" Voltar ")]),_c('v-btn',{staticClass:"mt-1 white--text next-step-button",class:{ 'ma-0': _vm.$isMobile, 'mx-2': !_vm.$isMobile },attrs:{"type":"button","disabled":invalid,"color":"secondary-color-1","block":_vm.$isMobile},on:{"click":_vm.handleConcludeDestination}},[_vm._v("Concluir")])],1)]}}],null,false,3711738414)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }