<template>
  <div class="d-flex flex-column">
    <div class="px-5 pt-5">
      <h2>Resumo da Destinação</h2>
      <p>
        Verifique os resíduos e a quantidade selecionada antes de concluir a
        destinação
      </p>
    </div>
    <v-divider></v-divider>
    <div>
      <v-data-table
        :headers="headers"
        :items="selectedResiduesFormattedStore"
        :items-per-page="selectedResiduesFormattedStore.length"
        hide-default-footer
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "SummaryResidue",

    data: () => ({
      headers: [
        { text: "Tipo de Resíduo", value: "residueType" },
        { text: "Subtipo", value: "description" },
        { text: "Quantidade (Kg)", value: "selectedWeightFormatted" }
      ]
    }),

    computed: {
      ...mapGetters("stock/destinationDemanufacturing", {
        selectedResiduesFormattedStore:
          "getCreateDestinationSelectedResiduesFormatted"
      })
    }
  };
</script>

<style lang="scss" scoped></style>
