<template>
  <div class="fill d-flex flex-column">
    <v-row>
      <v-col cols="12" lg="7">
        <v-card class="table-page">
          <residue-table
            v-if="hasSelectedCRC"
            :residueTypeList="residueTypeList"
          />
          <div v-else class="inherit-height d-flex flex-column justify-center">
            <data-not-found
              :title="['Para continuar,', 'você deve selecionar uma CRC']"
              :imgSrc="require('@/assets/crc.svg')"
            />
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="5">
        <v-card>
          <destination-select-crc @invalid="handleInvalid" />
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-5">
      <slot name="actions" :invalid="!hasSelectedCRC || invalid"></slot>
    </div>
  </div>
</template>

<script>
  import DataNotFound from "@/modules/core/views/components/DataNotFound.vue";

  import DestinationSelectCRC from "@/modules/stock/destination-demanufacturing/views/components/DestinationSelectCRC";
  import ResidueTable from "@/modules/stock/destination-demanufacturing/views/components/ResidueTable";

  import { pickBy, identity } from "lodash";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "ListResidueStock",

    components: {
      DestinationSelectCrc: DestinationSelectCRC,
      ResidueTable,
      DataNotFound
    },

    data: () => ({
      residueTypeList: [],
      hasSelectedCRC: false,

      invalid: true
    }),

    async mounted() {
      if (!this.userIsAdmin) {
        await this.fetchResiduesInStock();
      }
    },

    watch: {
      selectedCRCStore(newVal) {
        if (newVal?.id) {
          this.fetchResiduesInStock();
        } else {
          this.residueTypeList = [];
          this.hasSelectedCRC = false;
        }
      }
    },

    computed: {
      ...mapGetters({
        userIsAdmin: "user/getUserIsAdmin"
      }),
      ...mapGetters("stock/destinationDemanufacturing", {
        selectedCRCStore: "getCreateDestinationSelectedCrc"
      })
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/destinationDemanufacturing", [
        "getResidueSubtypesOnInventory"
      ]),

      async fetchResiduesInStock() {
        try {
          const payload = this.mountPayload();
          const { data } = await this.getResidueSubtypesOnInventory(payload);
          this.residueTypeList = data;

          if (!this.residueTypeList.length) {
            this.handleInvalid(true);
          }
          this.hasSelectedCRC = true;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      mountPayload() {
        const payload = {
          crcId: this.selectedCRCStore.id
        };

        return pickBy(payload, identity);
      },

      handleInvalid(invalid) {
        this.invalid = invalid;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
