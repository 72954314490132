<template>
  <div class="d-flex justify-center">
    <local-circle :color="color" />
  </div>
</template>

<script>
  import { recyclerStatus } from "@/modules/recycler/enums/recyclerStatus.enum";

  import LocalCircle from "@/modules/core/views/components/LocalCircle";

  export default {
    name: "RecyclerStatus",

    props: {
      status: {
        type: Object,
        required: true
      }
    },

    components: {
      LocalCircle
    },

    computed: {
      color() {
        return this.status.id === recyclerStatus.ACTIVE.id
          ? this.$vuetify.theme.themes.light["situation-2"]
          : this.$vuetify.theme.themes.light["situation-5"];
      }
    }
  };
</script>

<style lang="scss" scoped></style>
