<template>
  <div class="circle" :style="style"></div>
</template>

<script>
  export default {
    name: "LocalCircle",

    props: {
      color: {
        type: String,
        required: true
      }
    },

    computed: {
      style() {
        return `background-color: ${this.color}`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .circle {
    border-radius: 100%;
    width: 16px;
    height: 16px;
  }
</style>
