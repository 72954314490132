export const recyclerStatus = {
  ACTIVE: {
    id: 1,
    description: "Ativo"
  },
  INACTIVE: {
    id: 2,
    description: "Inativo"
  }
};
