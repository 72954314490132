<template>
  <div class="fill d-flex flex-column">
    <v-row>
      <v-col cols="12" lg="7">
        <v-card class="table-page">
          <residue-table
            v-if="initialized"
            :residueTypeList="residueTypeList"
            :select="true"
            v-model="selectedResidues"
          />
        </v-card>
      </v-col>
      <v-col cols="12" lg="5">
        <v-card>
          <residue-weight
            v-model="selectedResidues"
            @invalid="handleInvalidChange"
          />
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-5">
      <slot name="actions" :invalid="invalid"></slot>
    </div>
  </div>
</template>

<script>
  import ResidueTable from "@/modules/stock/destination-demanufacturing/views/components/ResidueTable";
  import ResidueWeight from "@/modules/stock/destination-demanufacturing/views/components/ResidueWeight";

  import { pickBy, identity } from "lodash";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "SelectResidue",

    components: {
      ResidueTable,
      ResidueWeight
    },

    data: () => ({
      residueTypeList: [],

      initialized: false
    }),

    mounted() {
      this.fetchResiduesInStock();
    },

    computed: {
      ...mapGetters("stock/destinationDemanufacturing", {
        selectedResiduesStore: "getCreateDestinationSelectedResidues",
        residuesInvalidStore: "getCreateDestinationResiduesInvalid",
        selectedCRCStore: "getCreateDestinationSelectedCrc"
      }),

      selectedResidues: {
        get() {
          return this.selectedResiduesStore;
        },
        set(newVal) {
          this.setCreateDestinationSelectedResidues(newVal);
        }
      },

      invalid: {
        get() {
          return this.residuesInvalidStore;
        },
        set(newVal) {
          this.setCreateDestinationResiduesInvalid(newVal);
        }
      }
    },

    methods: {
      ...mapActions("stock/destinationDemanufacturing", [
        "getResidueSubtypesOnInventory",
        "setCreateDestinationSelectedResidues",
        "setCreateDestinationResiduesInvalid"
      ]),

      async fetchResiduesInStock() {
        try {
          const payload = this.mountPayload();
          const { data } = await this.getResidueSubtypesOnInventory(payload);
          this.residueTypeList = data;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        } finally {
          this.initialized = true;
        }
      },

      mountPayload() {
        const payload = {
          crcId: this.selectedCRCStore.id
        };

        return pickBy(payload, identity);
      },

      handleInvalidChange(invalid) {
        this.invalid = invalid;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
