<template>
  <div class="d-flex flex-column">
    <div class="px-10 pt-5">
      <h2>Informações</h2>
      <v-row>
        <v-col cols="5" md="4"><span class="black-3--text">CRC:</span></v-col>
        <v-col cols="7" md="8'">{{ selectedCRCStore.name }}</v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="px-10 pt-5 mb-6">
      <h2>Reciclador</h2>

      <v-row>
        <v-col cols="5" md="4">
          <span class="black-3--text">Reciclador:</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ selectedRecycler.name }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" md="4">
          <span class="black-3--text">Representante:</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{
            selectedRecycler.recyclerAgent
          }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" md="4">
          <span class="black-3--text">Telefone(s):</span>
        </v-col>
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ phones }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" md="4"
          ><span class="black-3--text">Residuos:</span></v-col
        >
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ residues || "Não informado" }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5" md="4"
          ><span class="black-3--text">Endereço:</span></v-col
        >
        <v-col cols="7" md="8">
          <span class="black-1--text">{{ address }}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  import { parseAdressSingleLine } from "@/modules/core/helpers/addressHelpers";

  import { mapGetters } from "vuex";

  export default {
    name: "SummaryRecycler",

    mixins: [MasksMixin],

    computed: {
      ...mapGetters("stock/destinationDemanufacturing", {
        selectedRecyclerStore: "getCreateDestinationSelectedRecycler",
        selectedCRCStore: "getCreateDestinationSelectedCrc"
      }),

      selectedRecycler() {
        return this.selectedRecyclerStore[0];
      },

      phones() {
        return this.selectedRecycler.phones
          .map(phone => this.parsePhone(phone))
          .join(", ");
      },

      residues() {
        return this.selectedRecycler.residueSubtypes
          .map(residueSubtype => residueSubtype.description)
          .join(", ");
      },

      address() {
        return parseAdressSingleLine(this.selectedRecycler.address);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
