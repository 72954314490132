<template>
  <div>
    <p>
      Quantos quilos de {{ value.description }} vão ser destinados? (Máx:
      {{ value.weightFormatted }})
    </p>

    <div class="d-flex justify-center">
      <div class="d-flex">
        <btn-icon-press
          class="mt-4"
          color="#95A9B2"
          icon="mdi-minus-circle"
          :fnClick="decrement"
          :fnClickStart="startDecrement"
          :fnClickStop="stopInterval"
        />

        <validation-provider
          :name="name"
          :rules="{ required: isRequired, between: [min, value.weight] }"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="inputModel"
            type="number"
            min="0"
            class="field"
            placeholder="1~3"
            :error-messages="errors[0]"
            :suffix="suffix"
            outlined
          ></v-text-field>
        </validation-provider>

        <btn-icon-press
          class="mt-4"
          color="#95A9B2"
          icon="mdi-plus-circle"
          :fnClick="increment"
          :fnClickStart="startIncrement"
          :fnClickStop="stopInterval"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import BtnIconPress from "@/modules/core/views/components/BtnIconPress";
  import FieldMixin from "@/modules/core/mixins/fieldMixin";

  import { extend, ValidationProvider } from "vee-validate";
  import { between } from "vee-validate/dist/rules";

  extend("between", {
    ...between,
    message: "Peso inválido"
  });

  export default {
    name: "ResidueWeightField",

    props: {
      value: {
        type: null,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      min: {
        type: Number,
        required: false,
        default: () => 1
      },
      suffix: {
        type: String,
        required: false,
        default: () => "Kg"
      }
    },

    mixins: [FieldMixin],

    components: {
      BtnIconPress,
      ValidationProvider
    },

    data: () => ({
      interval: undefined,
      throttle: 250
    }),

    mounted() {
      const initialValue = this.value?.selectedWeight || 0;
      this.$emit("input", {
        ...this.value,
        selectedWeight: initialValue,
        invalid: !this.validate(initialValue)
      });
    },

    computed: {
      rules() {
        return {
          between: value => this.validate(value) || "Peso inválido"
        };
      },

      inputModel: {
        get() {
          return this.value?.selectedWeight || 0;
        },
        set(newVal) {
          this.$emit("input", {
            ...this.value,
            selectedWeight: newVal,
            invalid: !this.validate(newVal)
          });
        }
      }
    },

    methods: {
      decrement() {
        if (this.isBiggerThanMinimum(this.inputModel + 1)) {
          this.inputModel--;
        }
      },

      startDecrement() {
        if (!this.interval) {
          this.interval = setInterval(() => this.decrement(), this.throttle);
        }
      },

      increment() {
        if (this.isLessThanMaximum(this.inputModel + 1)) {
          this.inputModel++;
        }
      },

      startIncrement() {
        if (!this.interval) {
          this.interval = setInterval(() => this.increment(), this.throttle);
        }
      },

      stopInterval() {
        clearInterval(this.interval);
        this.interval = undefined;
      },

      validate(value) {
        return this.isBiggerThanMinimum(value) && this.isLessThanMaximum(value);
      },

      isBiggerThanMinimum(value) {
        return value > this.min;
      },

      isLessThanMaximum(value) {
        return value <= this.value.weight;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .field {
    width: 140px;
  }
</style>
