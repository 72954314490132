export const recyclerSituations = {
  REGULAR: {
    id: 1,
    description: "Regular"
  },
  IRREGULAR: {
    id: 2,
    description: "Irregular"
  }
};
