<template>
  <div>
    <div class="px-5">
      <v-text-field
        solo
        flat
        full-width
        background-color="white-1"
        color="secondary-color-1"
        placeholder="Buscar"
        prepend-inner-icon="mdi-magnify"
        @input="debounceInput"
      ></v-text-field>
    </div>

    <v-divider></v-divider>

    <div v-if="crcs.length" class="data-container">
      <div v-for="crc in crcs" :key="crc.id" class="pl-5">
        <v-checkbox
          v-model="selectedCrcs"
          dense
          :value="crc"
          color="primary-color-2"
          :multiple="multiple"
        >
          <template slot="label">
            <span class="flex-grow-0 ml-3 black-1--text">{{
              crc.description
            }}</span>
            <span class="flex-grow-1 ml-5">{{ crc.address }}</span>
          </template>
        </v-checkbox>
      </div>
      <div v-if="crcs.length">
        <infinite-loading spinner="waveDots" @infinite="loadMore">
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </div>

    <div v-if="!crcs.length" class="mt-5">
      <data-not-found
        :title="dataNotFound"
        :imgSrc="require('@/assets/crc.svg')"
        :btnAction="dataNotFound.btnAction"
      />
    </div>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import { debounce } from "lodash";

  import { parseAdressMinimal } from "@/modules/core/helpers/addressHelpers";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import InfiniteLoading from "vue-infinite-loading";

  export default {
    name: "SelectCRC",

    props: {
      value: {
        type: null
      },
      multiple: {
        type: Boolean,
        required: false,
        default: () => true
      }
    },

    components: {
      DataNotFound,
      InfiniteLoading
    },

    data: () => ({
      crcs: [],
      innerSelectedCrcs: undefined,
      searchTerm: "",
      filterPristine: true,
      page: 1,
      limit: 10
    }),

    async mounted() {
      this.crcs = await this.fetchCrcs();
    },

    computed: {
      dataNotFound() {
        return this.filterPristine
          ? "Busque por uma CRC"
          : "CRC não encontrada";
      },
      selectedCrcs: {
        get() {
          if (!this.innerSelectedCrcs && !!this.value) {
            return this.value;
          }
          return this.innerSelectedCrcs;
        },
        set(newVal) {
          this.innerSelectedCrcs = newVal;
          if (this.multiple) {
            this.$emit(
              "input",
              this.innerSelectedCrcs.map(selectedCrc => selectedCrc.id)
            );
          } else {
            this.$emit("input", this.innerSelectedCrcs?.id);
          }
          this.handleChange(this.innerSelectedCrcs);
        }
      }
    },

    methods: {
      ...mapActions("crc", ["getCRCs"]),
      ...mapActions(["toggleSnackbar"]),

      handleChange(crc) {
        this.$emit("selected", crc);
      },

      async fetchCrcs() {
        try {
          const payload = this.mountPayload();
          const { data } = await this.getCRCs(payload);
          return this.formatData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },
      formatData(data) {
        return data.map(crc => ({
          id: crc.id,
          description: crc.description,
          address: parseAdressMinimal(crc.address)
        }));
      },
      mountPayload() {
        return {
          description: this.searchTerm,
          page: this.page,
          limit: this.limit
        };
      },
      async loadMore($state) {
        this.page = this.page + 1;
        const data = await this.fetchCrcs();

        if (data.length) {
          this.crcs = [...this.crcs, ...data];
          $state.loaded();
        } else {
          $state.complete();
        }
      },
      debounceInput: debounce(async function(value) {
        this.filterPristine = false;
        this.searchTerm = value;
        this.page = 1;

        this.crcs = await this.fetchCrcs();
      }, 800)
    }
  };
</script>
<style lang="scss" scoped>
  .data-container {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
