<template>
  <v-container>
    <title-header :title="$route.meta.title" :subtitle="subtitle" />

    <list-residue-stock
      v-if="stepper.currentStep === steps.LIST_RESIDUES_AND_SELECT_CRC"
    >
      <template v-slot:actions="{ invalid }">
        <div class="d-flex justify-end">
          <v-btn
            type="button"
            :disabled="invalid"
            color="secondary-color-1"
            class="ma-0 mt-1 white--text next-step-button"
            :block="$isMobile"
            @click="handleInitDestination"
            >Nova Destinação</v-btn
          >
        </div>
      </template>
    </list-residue-stock>

    <select-residue v-if="stepper.currentStep === steps.SELECT_RESIDUES">
      <template v-slot:actions="{ invalid }">
        <div class="d-flex justify-end flex-wrap">
          <v-btn
            type="button"
            color="secondary-color-1"
            class="mt-1 black-3--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            outlined
            @click="handleBackActionOnFirstStep"
          >
            Voltar
          </v-btn>
          <v-btn
            type="button"
            :disabled="invalid"
            color="secondary-color-1"
            class="mt-1 white--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            @click="handleSelectedResidues"
            >Avançar</v-btn
          >
        </div>
      </template>
    </select-residue>

    <select-recycler v-if="stepper.currentStep === steps.SELECT_RECYCLER">
      <template v-slot:actions="{ invalid }">
        <div class="d-flex justify-end flex-wrap">
          <v-btn
            type="button"
            color="secondary-color-1"
            class="mt-1 black-3--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            @click="handleBackActionOnSecondStep"
            outlined
          >
            Voltar
          </v-btn>
          <v-btn
            type="button"
            :disabled="invalid"
            color="secondary-color-1"
            class="mt-1 white--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            @click="handleSelectedRecycler"
            >Avançar</v-btn
          >
        </div>
      </template>
    </select-recycler>

    <summary-step v-if="stepper.currentStep === steps.SUMMARY">
      <template v-slot:actions="{ invalid }">
        <div class="d-flex justify-end flex-wrap">
          <v-btn
            type="button"
            color="secondary-color-1"
            class="mt-1 black-3--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            @click="handleBackActionOnThirdStep"
            outlined
          >
            Voltar
          </v-btn>
          <v-btn
            type="button"
            :disabled="invalid"
            color="secondary-color-1"
            class="mt-1 white--text next-step-button"
            :class="{ 'ma-0': $isMobile, 'mx-2': !$isMobile }"
            :block="$isMobile"
            @click="handleConcludeDestination"
            >Concluir</v-btn
          >
        </div>
      </template>
    </summary-step>
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import ListResidueStock from "@/modules/stock/destination-demanufacturing/views/components/ListResidueStock";
  import SelectResidue from "@/modules/stock/destination-demanufacturing/views/components/SelectResidue";
  import SelectRecycler from "@/modules/stock/destination-demanufacturing/views/components/SelectRecycler";
  import SummaryStep from "@/modules/stock/destination-demanufacturing/views/components/SummaryStep";

  import { mapActions, mapGetters } from "vuex";

  import store from "@/store";

  export default {
    name: "DestinationDemanufacturing",

    beforeRouteLeave: (to, from, next) => {
      store.dispatch("stock/destinationDemanufacturing/resetCreateDestination");
      next();
    },

    components: {
      TitleHeader,
      ListResidueStock,
      SelectResidue,
      SelectRecycler,
      SummaryStep
    },

    data: () => ({
      steps: {
        LIST_RESIDUES_AND_SELECT_CRC: 0,
        SELECT_RESIDUES: 1,
        SELECT_RECYCLER: 2,
        SUMMARY: 3
      }
    }),

    computed: {
      ...mapGetters({
        userIsAdmin: "user/getUserIsAdmin"
      }),

      ...mapGetters("stock/destinationDemanufacturing", {
        stepper: "getCreateDestinationStepper",
        selectedCRCStore: "getCreateDestinationSelectedCrc",
        selectedRecyclerStore: "getCreateDestinationSelectedRecycler",
        selectedResidueFormattedStore:
          "getCreateDestinationSelectedResiduesFormatted"
      }),

      subtitle() {
        if (
          this.stepper.currentStep === this.steps.LIST_RESIDUES_AND_SELECT_CRC
        ) {
          return undefined;
        }
        return `Passo ${this.stepper.currentStep} de ${this.stepper.maxSteps}`;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),

      ...mapActions("stock/destinationDemanufacturing", [
        "setCreateDestinationCurrentStep",
        "createDestination",
        "resetCreateDestination"
      ]),

      handleInitDestination() {
        this.setCreateDestinationCurrentStep(this.steps.SELECT_RESIDUES);
      },

      handleBackActionOnFirstStep() {
        this.setCreateDestinationCurrentStep(
          this.steps.LIST_RESIDUES_AND_SELECT_CRC
        );
      },

      handleBackActionOnSecondStep() {
        this.setCreateDestinationCurrentStep(this.steps.SELECT_RESIDUES);
      },

      handleBackActionOnThirdStep() {
        this.setCreateDestinationCurrentStep(this.steps.SELECT_RECYCLER);
      },

      handleSelectedResidues() {
        this.setCreateDestinationCurrentStep(this.steps.SELECT_RECYCLER);
      },

      handleSelectedRecycler() {
        this.setCreateDestinationCurrentStep(this.steps.SUMMARY);
      },

      async handleConcludeDestination() {
        try {
          const payload = this.mountPayload();

          const response = await this.createDestination(payload);

          this.toggleSnackbar({
            text: response?.data?.message ?? "Registro criado com sucesso",
            type: "success"
          });

          this.resetCreateDestination();
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      mountPayload() {
        return {
          recyclerId: this.selectedRecyclerStore[0].id,
          crcId: this.selectedCRCStore.id,
          residuesSubtypes: this.selectedResidueFormattedStore.map(
            residueSubtype => ({
              residueSubtypeId: residueSubtype.id,
              weight: parseFloat(residueSubtype.selectedWeight)
            })
          )
        };
      }
    }
  };
</script>

<style lang="scss" scoped></style>
