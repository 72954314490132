<template>
  <div class="table-page">
    <v-expansion-panels
      accordion
      flat
      class="pa-2"
      v-if="residueTypeList.length && innerSelectedResidueTypes.length"
    >
      <expansion-panel v-for="(residueType, i) in residueTypeList" :key="i">
        <template slot="title">
          {{ residueType.description }}
        </template>
        <template slot="content">
          <v-data-table
            v-model="innerSelectedResidueTypes[i].selectedResidueSubtypes"
            :headers="headers"
            :items="residueType.residueSubtypes"
            :show-select="select"
            :items-per-page="residueType.residueSubtypes.length || 0"
            hide-default-footer
          ></v-data-table>
        </template>
      </expansion-panel>
    </v-expansion-panels>
    <div v-else class="inherit-height d-flex flex-column justify-center">
      <residue-subtype-inventory-not-found
        :title="[
          'Você ainda não possui nenhum',
          'resíduo para destinar',
          'Aguarde!'
        ]"
      />
    </div>
  </div>
</template>

<script>
  import ExpansionPanel from "@/modules/core/views/components/ExpansionPanel";
  import ResidueSubtypeInventoryNotFound from "@/modules/stock/destination-demanufacturing/views/components/ResidueSubtypeInventoryNotFound";

  import { flatMap } from "lodash";

  export default {
    name: "ResidueTable",

    components: {
      ExpansionPanel,
      ResidueSubtypeInventoryNotFound
    },

    props: {
      value: {
        type: null,
        required: false,
        default: () => []
      },

      residueTypeList: {
        type: Array,
        required: true
      },

      select: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },

    data: () => ({
      headers: [
        { text: "Subtipo de Resíduo", value: "description" },
        {
          text: "Peso em Estoque (kg)",
          value: "weightFormatted"
        }
      ],

      innerSelectedResidueTypes: []
    }),

    mounted() {
      this.initInnerSelectedResidueTypes();
    },

    watch: {
      innerSelectedResidueTypes: {
        deep: true,
        handler: function(_newVal) {
          this.handleResidueSubtypeSelected();
        }
      }
    },

    methods: {
      initInnerSelectedResidueTypes() {
        this.innerSelectedResidueTypes = this.residueTypeList.map(
          residueType => {
            const hasPreviousResidueType =
              this.value.length &&
              this.value.some(
                valueResidueType => valueResidueType.id === residueType.id
              );

            if (hasPreviousResidueType) {
              return this.value.find(
                valueResidueType => valueResidueType.id === residueType.id
              );
            }

            return {
              id: residueType.id,
              description: residueType.description,
              selectedResidueSubtypes: []
            };
          }
        );
      },

      handleResidueSubtypeSelected() {
        const newListSelectedResidueSubtypes = this.updateInnerSelectedResidueTypes();

        this.$emit(
          "input",
          newListSelectedResidueSubtypes.filter(
            residueType => !!residueType.selectedResidueSubtypes.length
          )
        );
      },

      updateInnerSelectedResidueTypes() {
        return this.innerSelectedResidueTypes.map(selectedResidueType => {
          const residueType = {
            ...selectedResidueType,
            selectedResidueSubtypes: [
              ...selectedResidueType.selectedResidueSubtypes
            ]
          };
          residueType.selectedResidueSubtypes.forEach(this.patchWeight);
          return residueType;
        });
      },

      patchWeight(residueSubtype) {
        const flatResidueSubtypes = this.getFlatResidueSubtypes(this.value);
        const residueSubtypeInFlat = flatResidueSubtypes.find(
          residueSubtypeFlat => residueSubtypeFlat.id === residueSubtype.id
        );
        if (residueSubtypeInFlat?.id) {
          residueSubtype.selectedWeight = residueSubtypeInFlat.selectedWeight;
        } else {
          residueSubtype.selectedWeight = 0;
        }
      },

      getFlatResidueSubtypes(selectedResidueTypesValue) {
        return flatMap(
          selectedResidueTypesValue,
          selectedResidueType => selectedResidueType.selectedResidueSubtypes
        );
      },

      filterFlatResidueSubtypes() {}
    }
  };
</script>

<style lang="scss" scoped></style>
