<template>
  <div>
    <div class="px-5">
      <v-text-field
        solo
        flat
        full-width
        background-color="white-1"
        color="secondary-color-1"
        placeholder="Buscar Reciclador"
        prepend-inner-icon="mdi-magnify"
        @input="debounceInput"
      ></v-text-field>
    </div>
    <v-divider />

    <div class="table-container">
      <v-data-table
        v-if="recyclers.length"
        :value="value"
        :items="recyclers"
        :headers="headers"
        :items-per-page="recyclers.length"
        selectable-key="canSelect"
        disable-pagination
        hide-default-footer
        show-select
        single-select
        @input="$emit('input', $event)"
      >
        <template v-slot:[`item.situation`]="{ item }">
          <recycler-situation :situation="item.situation" />
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <recycler-status :status="item.status" />
        </template>

        <template v-slot:[`body.append`]="{ headers }">
          <tr>
            <td :colspan="headers.length">
              <infinite-loading spinner="waveDots" @infinite="loadMore">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
              </infinite-loading>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div v-else class="mt-10">
        <data-not-found
          title="Reciclador não encontrado."
          :imgSrc="require('@/assets/recycler.svg')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import RecyclerSituation from "@/modules/stock/destination-demanufacturing/views/components/RecyclerSituation";
  import RecyclerStatus from "@/modules/stock/destination-demanufacturing/views/components/RecyclerStatus";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import InfiniteLoading from "vue-infinite-loading";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";

  import { recyclerStatus } from "@/modules/recycler/enums/recyclerStatus.enum";
  import { recyclerSituations } from "@/modules/recycler/enums/recyclerSituations.enum";

  import { debounce, pickBy, identity } from "lodash";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "TableSelectRecycler",

    props: {
      value: {
        type: null
      }
    },

    components: {
      RecyclerSituation,
      RecyclerStatus,
      InfiniteLoading,
      DataNotFound
    },

    mixins: [MasksMixin],

    data: () => ({
      recyclers: [],

      searchInput: "",
      filterPristine: true,

      residueSubtypeIds: [],

      page: 1,
      limit: 15,

      innerSelectedRecycler: undefined,

      headers: [
        { text: "Reciclador", value: "name" },
        { text: "Representante", value: "recyclerAgent" },
        { text: "Telefone", value: "phone" },
        { text: "Status", value: "status", sortable: false, align: "center" },
        {
          text: "Situação",
          value: "situation",
          sortable: false,
          align: "center"
        }
      ]
    }),

    async mounted() {
      this.residueSubtypeIds = this.selectedResiduesFormattedStore.map(
        residueSubtype => residueSubtype.id
      );
      this.recyclers = await this.searchRecyclers();
    },

    computed: {
      ...mapGetters("stock/destinationDemanufacturing", {
        selectedResiduesFormattedStore:
          "getCreateDestinationSelectedResiduesFormatted"
      })
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("recycler", ["getRecyclers"]),

      clear() {
        this.page = 1;
      },

      debounceInput: debounce(async function(value) {
        this.clear();

        this.filterPristine = false;
        this.searchInput = value;

        this.recyclers = await this.searchRecyclers();
      }, 800),

      async searchRecyclers() {
        try {
          const payload = this.mountPayload();
          const { data } = await this.getRecyclers(payload);

          return this.formatData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatData(data) {
        return data.map(recycler => {
          return {
            ...recycler,
            phone: this.parsePhone(recycler.phones[0]),
            status: this.getRecyclerStatus(recycler),
            situation: this.getRecyclerSituation(recycler),
            canSelect: this.getCanSelect(recycler)
          };
        });
      },

      getRecyclerStatus(recycler) {
        return recycler.inactivatedAt
          ? recyclerStatus.INACTIVE
          : recyclerStatus.ACTIVE;
      },

      getRecyclerSituation(recycler) {
        return recycler.isRegular
          ? recyclerSituations.REGULAR
          : recyclerSituations.IRREGULAR;
      },

      getCanSelect(recycler) {
        return !recycler.inactivatedAt && recycler.isRegular;
      },

      mountPayload() {
        const payload = {
          name: this.searchInput,
          residueSubtypeIds: this.residueSubtypeIds,
          page: this.page,
          limit: this.limit
        };

        return pickBy(payload, identity);
      },

      async loadMore($state) {
        this.page = this.page + 1;
        const data = await this.searchRecyclers();

        if (data.length) {
          this.recyclers = [...this.recyclers, ...data];
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .table-container {
    max-height: 500px;
    overflow-y: auto;
  }
</style>
