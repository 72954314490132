<template>
  <data-not-found
    :title="text"
    :imgSrc="require('@/assets/residue-inventory.svg')"
  />
</template>

<script>
  import DataNotFound from "@/modules/core/views/components/DataNotFound.vue";

  export default {
    name: "ResidueSubtypeInventoryNotFound",

    props: {
      title: {
        type: Array,
        required: false
      }
    },

    components: {
      DataNotFound
    },

    computed: {
      text() {
        return this.title ?? "Resíduo não encontrado!";
      }
    }
  };
</script>

<style></style>
