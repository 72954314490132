<template>
  <div class="fill d-flex flex-column">
    <v-row>
      <v-col cols="12" lg="7">
        <v-card class="pt-7">
          <table-select-recycler v-model="selectedRecycler" />
        </v-card>
      </v-col>
      <v-col cols="12" lg="5">
        <v-card>
          <summary-residue />
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-5">
      <slot name="actions" :invalid="!selectedRecycler.length"></slot>
    </div>
  </div>
</template>

<script>
  import TableSelectRecycler from "@/modules/stock/destination-demanufacturing/views/components/TableSelectRecycler";
  import SummaryResidue from "@/modules/stock/destination-demanufacturing/views/components/SummaryResidue";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "SelectRecycler",

    components: {
      TableSelectRecycler,
      SummaryResidue
    },

    computed: {
      ...mapGetters("stock/destinationDemanufacturing", {
        selectedRecyclerStore: "getCreateDestinationSelectedRecycler"
      }),

      selectedRecycler: {
        get() {
          return this.selectedRecyclerStore;
        },
        set(newVal) {
          this.setCreateDestinationSelectedRecycler(newVal);
        }
      }
    },

    methods: {
      ...mapActions("stock/destinationDemanufacturing", [
        "setCreateDestinationSelectedRecycler"
      ])
    }
  };
</script>

<style lang="scss" scoped></style>
