<template>
  <div class="pt-4">
    <div class="px-5">
      <h3>Defina quantos quilos serão destinados</h3>
      <p>
        Ao enviar esses resíduo para o Reciclador você concorda que eles sairão
        do Programando o Futuro, e não poderão mais ser contabilizados ou
        alterados.
      </p>
    </div>
    <v-divider />
    <div class="py-4 px-5 residue-type-container">
      <validation-observer v-if="value.length" ref="validationObserver">
        <v-form>
          <template v-for="(residueType, index) in value">
            <residue-weight-container
              :value="residueType"
              @input="handleResidueTypeChange(index, $event)"
              :key="`container-${index}`"
              class="my-2"
            />
            <v-divider
              v-if="isNotLastItem(index, value)"
              :key="`divider-${index}`"
            />
          </template>
        </v-form>
      </validation-observer>
      <div v-else class="inherit-height d-flex flex-column justify-center">
        <residue-subtype-inventory-not-found
          :title="['Informe a quantidade depois de', 'selecionar os resíduos']"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ResidueWeightContainer from "@/modules/stock/destination-demanufacturing/views/components/ResidueWeightContainer";
  import ResidueSubtypeInventoryNotFound from "@/modules/stock/destination-demanufacturing/views/components/ResidueSubtypeInventoryNotFound";

  import { ValidationObserver } from "vee-validate";

  import { mapGetters } from "vuex";

  export default {
    name: "ResidueWeight",

    props: {
      value: {
        type: null,
        required: true
      }
    },

    components: {
      ResidueWeightContainer,
      ValidationObserver,
      ResidueSubtypeInventoryNotFound
    },

    data: () => ({
      residueTypeGroup: []
    }),

    computed: {
      ...mapGetters("stock/destinationDemanufacturing", {
        selectedResiduesFormattedStore:
          "getCreateDestinationSelectedResiduesFormatted"
      })
    },

    methods: {
      isNotLastItem(index, array) {
        return array.length - 1 !== index;
      },

      handleResidueTypeChange(index, newVal) {
        const copy = [...this.value];
        copy[index] = newVal;
        this.$emit("input", copy);
        this.validateForm();
      },

      async validateForm() {
        const validationObserver = this.$refs.validationObserver;
        await validationObserver.validate({ silent: true });

        const invalid =
          validationObserver?.flags?.invalid === undefined
            ? true
            : validationObserver.flags.invalid;

        this.$emit("invalid", invalid);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .residue-type-container {
    max-height: 466px;
    overflow-y: auto;
  }
</style>
