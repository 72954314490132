<template>
  <div class="fill d-flex flex-column">
    <v-row>
      <v-col cols="12" lg="7">
        <v-card>
          <summary-residue />
        </v-card>
      </v-col>
      <v-col cols="12" lg="5">
        <v-card>
          <summary-recycler />
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-5">
      <slot name="actions" :invalid="false"></slot>
    </div>
  </div>
</template>

<script>
  import SummaryResidue from "@/modules/stock/destination-demanufacturing/views/components/SummaryResidue";
  import SummaryRecycler from "@/modules/stock/destination-demanufacturing/views/components/SummaryRecycler";

  export default {
    name: "SummaryStep",

    components: {
      SummaryResidue,
      SummaryRecycler
    }
  };
</script>

<style lang="scss" scoped></style>
