<template>
  <v-expansion-panel>
    <v-expansion-panel-header hide-actions>
      <template v-slot:default="{ open }">
        <div class="d-flex align-center">
          <div class="mr-5">
            <v-icon color="black-4">{{
              open ? "mdi-chevron-up-circle" : "mdi-chevron-down-circle"
            }}</v-icon>
          </div>
          <div>
            <slot name="title"> </slot>
          </div>
        </div>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div :class="{ 'container-content': overflow }">
        <slot name="content"></slot>
      </div>
    </v-expansion-panel-content>
    <v-divider></v-divider>
  </v-expansion-panel>
</template>

<script>
  export default {
    name: "ExpansionPanel",

    props: {
      overflow: {
        type: Boolean,
        required: false,
        default: () => true
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .v-expansion-panel--active > .v-expansion-panel-header {
    background-color: $background;
  }

  .v-expansion-panel-content > .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-expansion-panel-header {
    padding: 2px;
  }

  .container-content {
    max-height: 400px;
    overflow-y: auto;
  }
</style>
