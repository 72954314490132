<template>
  <div class="pointer mx-2">
    <v-icon
      :color="color"
      @click="fnClick"
      @mousedown="fnClickStart"
      @mouseleave="fnClickStop"
      @mouseup="fnClickStop"
      @touchstart="fnClickStart"
      @touchend="fnClickStop"
      @touchcancel="fnClickStop"
      size="30"
      >{{ icon }}</v-icon
    >
  </div>
</template>

<script>
  export default {
    name: "BtnIconPress",

    props: {
      color: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      fnClick: {
        type: Function,
        required: true
      },
      fnClickStart: {
        type: Function,
        required: true
      },
      fnClickStop: {
        type: Function,
        required: true
      }
    }
  };
</script>

<style></style>
